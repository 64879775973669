* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    min-height: 100vh;
    background: #FCF8F6;
}

#root {
    min-height: 100vh;
    background: #FCF8F6;
    display: flex;
}


